import Highway from '@dogstudio/highway';

import Swiper from 'swiper';
import SwiperCore, { Navigation, Pagination } from 'swiper/core';
import 'swiper/swiper-bundle.css';

SwiperCore.use([Navigation, Pagination]);

export default class SwiperRenderer extends Highway.Renderer {
  // Hooks/methods
  onEnter() {
    this.swiper = new Swiper('.swiper-container', {
      lazy: true,
      loop: true,
      pagination: {
        el: '.swiper-pagination',
      },
      navigation: {
        nextEl: '.swiper-next',
        prevEl: '.swiper-prev',
      }
    });
  }
  onLeave() {
    this.swiper.destroy();
  }
  onEnterCompleted() {

  }
  onLeaveCompleted() {

  }
}
