import LocomotiveScroll from 'locomotive-scroll';
import 'locomotive-scroll/dist/locomotive-scroll.css';


const scroll = new LocomotiveScroll({
  el: document.querySelector('[data-scroll-container]'),
  smooth: true,
  getDirection: true,
});

// Navigation styling based on position in page

const navbar = document.getElementById('navbar');

scroll.on("scroll", instance => {

  let isSticky = instance.scroll.y >= navbar.getBoundingClientRect().height + 300;
  // Navbar at the top of page style
  if (isSticky) {
    navbar.classList.add('py-5');
    navbar.classList.remove('py-10');
  }
  else {
    navbar.classList.remove('py-5');
    navbar.classList.add('py-10');
  }

  // On scroll sticky header
  if (instance.direction == 'down' ) {
    navbar.classList.remove('translate-y-0');
    navbar.classList.add('-translate-y-full');
  }
  else {
    navbar.classList.add('translate-y-0');
    navbar.classList.remove('-translate-y-full');
  }
});


export default scroll;