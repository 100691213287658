//Highway
import Highway from '@dogstudio/highway';

//Highway Renderers
import HomeRenderer from "./renderer/home-renderer";
import SwiperRenderer from "./renderer/swiper-renderer";

//Highway Transitions
import Fade from "./transition/fade";

const H = new Highway.Core({
  renderers: {
    // name: HomeRenderer,
    about: SwiperRenderer,
    project: SwiperRenderer,
    default: HomeRenderer
  },
  transitions: {
    name: Fade,
    default: Fade
  }
}
);

// Listen Events

H.on('NAVIGATE_END', ({ to }) => {
  // Call Quicklink
  //listen();
});


window.addEventListener('load', () => {
  // Call Quicklink
  //listen();
});
