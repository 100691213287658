//Tailwind & css
import './scss/app.scss';
import './fonts/fonts.css';

//Alpine JS
import 'alpinejs';

// Locomotive
import './js/locomotive/lm';

// Highway
import './js/highway/init'


// Explose Appear
import './js/appear'

// Cookie

import Cookies from '@explose/cookies';

window.cookies = new Cookies();


// Loader

import gsap from 'gsap';

const loader = document.getElementById('loader');

window.addEventListener('load', e => {
  gsap.to("#loader", {opacity: 0, duration: 1});
  loader.remove();
});