import Highway from '@dogstudio/highway';
import { gsap } from "gsap";
import scroll from '../../locomotive/lm';
import appear from '../../appear';


export default class Fade extends Highway.Transition {

  out({ from, trigger, done }) {
    gsap.to(from, { duration: .2, opacity: 0, onComplete: done });
    scroll.scrollTo(0, { duration: 0 });

  }

  in({ from, to, trigger, done }) {
    from.remove();
    scroll.update();
    appear.update();
    gsap.fromTo(to, { opacity: 0 }, { duration: 0, opacity: 1, onComplete: done });

  }

}
