import Appear from '@explose/appear'

export default new Appear({
  animations: {
    fadeIn: {
      from: { y: 100, opacity: 0, },
      to: { y: 0, opacity: 1, },
      duration: 1
    }
  }
});

